<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col md="8" lg="6">
        <v-card class="elevation-4">
          <div class="pa-7 pa-sm-12 text-center">
            <v-overlay :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              The Billfish Foundation Tag & Release Application
            </h2>
            <a @click="goToHomePage">
              <img src="@/assets/billfish-logo-new.png" alt="Billfish" />
            </a>
            <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
              Sign in
            </h2>

            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              action="/dashboards/analytical"
            >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                class="mt-4"
                required
                outlined
              ></v-text-field>
              <v-text-field
                v-model="password"
                :rules="passwordRules.concat(passwordRequirements)"
                label="Password"
                required
                outlined
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              ></v-text-field>

              <v-row justify="end" align="center">
                <v-col>
                  <v-checkbox
                    class="mb-2"
                    v-model="remember"
                    label="Remember me?"
                    required
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <a @click="recoverPwd" class="link">Forgot password?</a>
                </v-col>
              </v-row>

              <v-btn
                :disabled="!valid"
                color="info"
                block
                class="mr-4 mt-2"
                submit
                @click="submit"
                >Sign In</v-btn
              >
            </v-form>
            <div class="text-center justify-space-around d-flex mt-5">
              <v-chip pill @click.prevent="goToHomePage">
                <v-avatar left>
                  <v-btn color="primary" class="white--text">
                    <v-icon>mdi-home-circle</v-icon>
                  </v-btn>
                </v-avatar>
                TBF Homepage
              </v-chip>
            </div>
            <v-row justify="end" align="center" class="mt-5">
              <v-col cols="8" align-self="center" class="text-start">
                <a @click="goToHomePage" class="link">
                  &copy;
                  <span v-text="` - The Billfish Foundation - ${year}`" />
                </a>
              </v-col>
              <v-spacer />
              <v-col>
                <a href="privacy" target="_blank" class="link">
                  <v-list-item-content v-text="'Privacy'" />
                </a>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { notifyMessage } from '../../helpers/handler';
export default {
  name: 'BoxedLogin',
  data: () => ({
    valid: true,
    password: '',
    show1: false,
    passwordRules: [(v) => !!v || 'Password is required'],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    remember: false,
    overlay: false
  }),
  computed: {
    passwordRequirements() {
      const strongRegex = new RegExp('(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})');
      return (v) => strongRegex.test(v) || 'Password is not strong enough';
    },
    year() {
      return new Date().getFullYear();
    }
  },
  methods: {
    ...mapActions('users', ['login']),
    goToHomePage() {
      window.location.assign('https://billfish.org/');
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.overlay = true;
        try {
          const { data } = await this.login({
            email: this.email,
            password: this.password,
            remember: this.remember,
            client: null
          });
          if (data?.token) {
            if (String(data.user.roles[0]).toLowerCase() === 'admin') {
              localStorage.setItem('data', JSON.stringify(data));
              this.$router.push({
                path: 'searches'
              });
            } else
              notifyMessage('You need a higher level permission to log in');
          } else notifyMessage(data?.message || 'Wrong Credentials');
        } catch (e) {
          notifyMessage('Something went wrong');
        }
        this.overlay = false;
      }
    },
    recoverPwd() {
      this.$router.push({ path: '/forgot-password' });
    }
  }
};
</script>

<style scoped>
img {
  background-color: dodgerblue;
  border-radius: 10px;
}
</style>
